import React, { useEffect, useRef } from "react";
import { Form, InputGroup } from "react-bootstrap";
import "./index.css";

function TextInputGroup(props) {
  const inputRef = useRef(null);

  useEffect(() => {
    // Moving cursor to the end
    if (props.inputType !== "email") {
      inputRef.current.selectionStart = inputRef.current.value.length;
      inputRef.current.selectionEnd = inputRef.current.value.length;
    }
  }, [props.inputType]);

  return (
    <Form.Group controlId={props.controlId} className={props.className} style={props.style}>
      <Form.Label>{props.label}</Form.Label>
      <InputGroup className="mb-2">
        <Form.Control
          required={props.required}
          type={props.inputType}
          placeholder={props.placeholder}
          disabled={props.disabled}
          value={props.value}
          onChange={props.onChange ? e => props.onChange(e.target.value) : null}
          ref={inputRef}
          isInvalid={props.invalidMessage}
        />
        <InputGroup.Prepend>
          <InputGroup.Text style={{ borderRadius: "0px 5px 5px 0px" }}>{props.icon}</InputGroup.Text>
        </InputGroup.Prepend>
        <Form.Control.Feedback type="invalid">{props.invalidMessage}</Form.Control.Feedback>
      </InputGroup>
    </Form.Group>
  );
}

export default TextInputGroup;
