import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { useHistory, useLocation } from "react-router-dom";
import PageLoader from "../components/page-loader";
import { login } from "../redux/actions/authActions";
import { moreThan5Weeks } from "../utils";

const adminCompatibleViews = ["/exercises/"];

const Restricted = BaseComponent => {
  const AdminComponent = props => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { pathname } = useLocation();
    const { isAuthenticated, user = {}, attempts, isLoading } = useSelector((state: RootState) => state.auth);

    function getIsAdminCompatible(pathName) {
      let result = false;
      adminCompatibleViews.forEach(el => {
        if (pathName.startsWith(el)) result = true;
      });
      return result;
    }

    const isAdminCompatible = getIsAdminCompatible(pathname);

    const checkPermissions = useCallback(async () => {
      //Not Authenticated
      if (!isAuthenticated) {
        history.push("/login");
        return;
      }

      //Import user
      if (isAuthenticated && user && user["custom:permissionLevel"] === "3") {
        if (isAdminCompatible) {
          return;
        } else if (!isAdminCompatible) {
          history.push("/import-users");
          return;
        }
      }

      //Admin
      if (isAuthenticated && user && user["custom:isAdmin"] === "true") {
        if (isAdminCompatible) {
          return;
        } else if (!isAdminCompatible) {
          history.push("/admin");
          return;
        }
      }

      if (user?.summary?.noChronicPain && !moreThan5Weeks(user?.summary?.applicationSubmittedTimestamp)) {
        history.push("/intake-survey/no-fit");
        return;
      }

      // Pain Area Selection
      if (isAuthenticated && !user?.summary?.applicationSubmittedTimestamp && !user?.summary?.intakeCompleted) {
        history.push("pain-area-selection/");
        return;
      }

      //Post-Graduated
      if (isAuthenticated && user?.summary?.status === "POST-GRADUATED") {
        history.push("/postgraduate");
        return;
      }

      // Archived - Not Accepted - Dropped Out
      if (
        isAuthenticated &&
        (user?.summary?.applicantArchived || user?.summary?.status === "DROPPED_OUT" || user?.summary?.status === "NOT_ACCEPTED")
      ) {
        history.push("/intake-process-selection");
        return;
      }

      //Post - Dropped Out
      if (isAuthenticated && user?.summary?.status === "POST-DROPPED_OUT") {
        history.push("/postDroppedOut");
        return;
      }

      //Incomplete Survey
      if (isAuthenticated && !user?.summary?.intakeCompleted && !(user?.summary?.painAreaList?.length > 1)) {
        const painAreaSelected = localStorage.getItem("painAreaSelected");
        if (painAreaSelected) {
          history.push(`survey/${painAreaSelected}/intake${user?.summary?.intakePartResponded === 1 ? "1" : ""}`);
        } else if (isAuthenticated && !user?.summary?.applicationSubmittedTimestamp && !user?.summary?.intakeCompleted) {
          history.push("pain-area-selection/");
        } else {
          history.push(`survey/${user?.summary?.painArea}/intake${user?.summary?.intakePartResponded === 1 ? "1" : ""}`);
        }
        return;
      }
      // Additional terms
      if (isAuthenticated && user && (user.summary.additionalTerms === "Pending" || user.summary.additionalTerms === "Declined")) {
        history.push("/additionalTerms");
        return;
      }

      //Pending Approval
      // if (user["custom:isAdmin"] !== "true" && isAuthenticated && !user?.summary?.applicationAccepted && user?.summary?.intakeCompleted) {
      //   const surveyType = user.summary.intakePartResponded && user.summary.intakePartResponded === 1 ? "intake1" : "intake";
      //   history.push("/intake-survey/submitted", { painArea: user.summary.painArea, surveyType });
      // }
      return;
    }, [history, isAuthenticated, user, isAdminCompatible]);

    useEffect(() => {
      if (attempts > 0 && !isLoading) checkPermissions();
    }, [attempts, checkPermissions, isLoading]);

    useEffect(() => {
      setTimeout(() => dispatch(login()), 100);
    }, [pathname, dispatch]);

    if (isAuthenticated) return <BaseComponent {...props} />;
    return <PageLoader />;
  };
  return AdminComponent;
};

export { Restricted };
