import { Auth } from "aws-amplify";
import { surveyAnswer, userSummary, memberPrograms, memberPainArea } from "../services";
import { mskApi } from "../services/config";
import { Header } from "../constants";
import { SummaryDTOTransformer } from "./DTO/SummaryDTO";

export async function Authenticate(email, code) {
  try {
    const user = await Auth.signIn(email);
    await Auth.sendCustomChallengeAnswer(user, code);
    await Auth.currentUserInfo();
    return getAuthenticateData();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    throw new Error(e);
  }
}

export async function updateSession() {
  try {
    const [session] = await Promise.all([Auth.currentSession()]);
    const jwtToken = (session || {}).getAccessToken().getJwtToken();
    const header: Header = {
      authorization: jwtToken,
    };
    mskApi.setHeaders(header);
    localStorage.setItem("rkAccessToken", jwtToken);
    return [session];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    throw new Error(e);
  }
}

export async function getAuthenticateData(_paId?) {
  let paId = _paId;
  try {
    const [user, session] = await Promise.all([Auth.currentAuthenticatedUser(), Auth.currentSession()]);
    const jwtToken = (session || {}).getAccessToken().getJwtToken();
    const header: Header = {
      authorization: jwtToken,
    };
    mskApi.setHeaders(header);
    localStorage.setItem("rkAccessToken", jwtToken);
    const summaryDTO = await userSummary.get();
    let programsDTO;
    let surveyAnswerDTO;
    let painAreaDTO;
    
    // If no paId, fill it with main_pain_area_id
    if (!paId) paId = summaryDTO?.data?.member?.main_pain_area_id;
    
    if (paId) {
      // Launch all requests concurrently
      [surveyAnswerDTO, programsDTO, painAreaDTO] = await Promise.all([
        surveyAnswer.get(String(paId)),
        memberPrograms.get(`${paId}`),
        memberPainArea.get(String(paId))
      ]);
    }

    const summary = {
      data: new SummaryDTOTransformer(summaryDTO.data, programsDTO?.data, surveyAnswerDTO?.data, painAreaDTO?.data).getModel(),
    };

    return [user, session, summary];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    throw new Error(e);
  }
}

export async function LogOut() {
  await Auth.signOut();
  Object.keys(localStorage).forEach(key => {
    if (key.startsWith("rk")) {
      localStorage.removeItem(key);
    }
  });
  return localStorage.removeItem("rkAccessToken");
}
