import React from "react";
import { useTranslation } from "react-i18next";
import { Col } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { setSelectedLanguage } from "../../redux/actions/authActions";
import EsFlag from "./EsFlag";
import UsFlag from "./UsFlag";

export const LanguageSelector = props => {
  const { languageChangeTrigger, vertical, smallSize } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const changeLanguage = (lng: string) => {
    if (languageChangeTrigger) languageChangeTrigger();
    dispatch(setSelectedLanguage(lng));
  };

  return (
    <>
      {!smallSize ? (
        <Col className="d-none d-md-block" style={{ right: "1em" }}>
          <div style={{ display: "flex", justifyContent: "right" }}>
            <div style={{ cursor: "pointer" }} onClick={() => changeLanguage("es")}>
              <EsFlag />
              <span style={{ marginLeft: "8px" }}>{t("Spanish")}</span>
            </div>
            <div style={{ paddingLeft: 10, cursor: "pointer" }} onClick={() => changeLanguage("en")}>
              <UsFlag />
              <span style={{ marginLeft: "8px" }}>{t("English")}</span>
            </div>
          </div>
        </Col>
      ) : null}
      <Col className={smallSize ? "" : "d-md-none"} style={{}}>
        <div style={{ display: "flex", justifyContent: "right", flexDirection: vertical ? "column" : "row" }}>
          <div style={{ paddingLeft: smallSize ? 10 : 0, cursor: "pointer" }} onClick={() => changeLanguage("es")}>
            <EsFlag />
          </div>
          <div style={{ paddingLeft: 10, cursor: "pointer" }} onClick={() => changeLanguage("en")}>
            <UsFlag />
          </div>
        </div>
      </Col>
    </>
  );
};
