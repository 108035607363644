/* eslint-disable @typescript-eslint/no-explicit-any */
import { DTO } from "./DTOInterface";
import { ProfileDTOTransformer, ShortPA, ShortPADTO } from "./ProfileDTO";
import { TMemberProgramsDTO } from "./ProgramsType";

export interface SummaryMemberDTO {
  language: string;
  coach?: TSummaryCoach;
  last_name: string;
  last_logged_in: any;
  phone_number: string;
  birth_date: string;
  email: string;
  id: string;
  first_name: string;
  member: MemberDTO;
  gender: string;
}
export interface TSummaryCoach {
  user_id: string;
  img_url: any;
  permission_level: number;
  calendar_link: string;
  id: number;
  position: string;
  is_import_user: any;
  introduction: any;
  meet_link: any;
  user: User;
  deleted: boolean;
}

export interface MemberDTO {
  main_pain_area_id: number;
  company: CompanySummaryDTO;
  notifications_paused: any;
  company_id: string;
  automated_emails: any;
  meet_link: any;
  terms_accepted: any;
  creation_timestamp: any;
  residence_state: string;
  how_met_us: any;
  pain_areas: ShortPADTO[];
  presurgery_program: any;
  id: number;
  verified_user: boolean;
  external_id: any;
  registration_method: string;
  automated_sms: any;
  recommendations: any;
  exercise_reminder: any;
  relationship: any;
  video_quality: any;
  accidental_signup: any;
  apero_id: any;
  testimonial_submitted: any;
  user_id: string;
  member_risks: any;
  first_login: any;
  light_sensitive: any;
  utm_object: string[];
  user: User;
  current_coach_meet_link: null | string;
}

export interface CompanySummaryDTO {
  company_name: string;
  deleted: boolean;
  slug: string;
  additional_terms: boolean;
}

export interface PainAreaFullDTO {
  intake_completed: boolean;
  favorites: any;
  last_program_position: any;
  notes: string;
  accepted_in_limbo_date: any;
  red_flag: any;
  coach_id: any;
  start_date: any;
  intake_answer_id: number;
  intro_call_completed: any;
  red_flags_count: any;
  intro_call_channel: any;
  intro_call_date: any;
  testimonials: any[];
  intake_part_responded: any;
  time_zone: any;
  unpause_date: any;
  stats: Stats;
  intro_call_event_id: any;
  applicant_notes: any;
  potentially_not_a_fit: any;
  paused_reason: any;
  current_week: any;
  application_submitted: number;
  coach: any;
  status_id: number;
  id: number;
  not_a_fit: any;
  no_fit_questions: any[];
  member_id: number;
  no_show_intro_call: any;
  last_response: number;
  pain_area_id: string;
  current_program_id: any;
  no_chronic_pain: null | boolean;
  status: {
    status: string;
    member_painarea_id: number;
    id: number;
    modified_date: number;
  };
}

export interface Stats {
  lastDate: number;
  lastPain: number;
  firstDate: number;
  firstPain: number;
  currentDate: number;
  currentPain: number;
  currentSatisfaction: any;
}

export interface User {
  language: string;
  last_name: string;
  last_logged_in: any;
  phone_number: string;
  birth_date: string;
  email: string;
  coach?: any;
  id: string;
  first_name: string;
  gender: string;
}

///  Export Summary object
export interface TSummary {
  memberId?: number;
  coachId?: number;
  mainPainAreaId?: number;
  currentProgram?: CurrentProgram;
  currentProgramDays?: CurrentProgramDay[];
  intakeInProcess?: boolean;
  applicationAccepted?: boolean;
  startDate?: string;
  pulseAnswered?: boolean;
  coach: Coach;
  permissionLevel: any;
  painAreaList: ShortPA[];
  painArea?: string;
  intakeCompleted?: boolean;
  applicationSubmittedTimestamp?: number;
  lastStatusDate: number;
  currentWeek: number;
  additionalTerms: string;
  lang: string;
  monthlyAnswered?: boolean;
  showRecommendation: boolean;
  lightSensitive: boolean;
  intakePartResponded: any;
  exerciseReminder: {};
  favorites: {};
  videoQuality: boolean;
  completedPrograms: number;
  status?: string;
  intakeAnswerPaId?: number;
  coachData?: TSummaryCoach;
  lastCoachMeetLink?: string | null;
  intakeAnswerPartResponded?: null | 1 | 2;
  noChronicPain?: null | boolean;
  introCallDate?: null | number;
  timeZone?: null | string;
}

export interface CurrentProgram {
  completionPercent: number;
  title: string;
  slug: string;
  description: string;
  currentDay: string;
  position: number;
}

export interface CurrentProgramDay {
  title: string;
  started: boolean;
  completed: boolean;
  exercises: Exercise[];
}

export interface Exercise {
  title: string;
  slug: string;
  video: Video;
}

export interface Video {
  url: string;
  hlsUrl: string;
  thumbnailUrl: string;
}

export interface Coach {
  name: string;
  position: string;
  email: string;
  phoneNumber: string;
  calendarLink: string;
  imageUrl: string;
}

export interface TProgramsMemberDTO {
  today_answered_pulse: boolean;
  last_month_monthly: boolean;
  pulse_graph: any;
  surveys: any;
}

export class SummaryDTOTransformer implements DTO {
  DTO: SummaryMemberDTO;
  MPDTO: TMemberProgramsDTO | undefined;
  MSDTO: TProgramsMemberDTO | undefined;
  PADTO: PainAreaFullDTO | undefined;
  constructor(
    SummaryMDTO: SummaryMemberDTO,
    programsMemberDTO: TMemberProgramsDTO,
    memberSurveyDTO: TProgramsMemberDTO,
    painAreaDTO: PainAreaFullDTO
  ) {
    this.DTO = SummaryMDTO;
    this.MPDTO = programsMemberDTO;
    this.MSDTO = memberSurveyDTO;
    this.PADTO = painAreaDTO;
  }

  getOriginalDTO() {
    return this.DTO;
  }

  getModel() {
    const convertedItem = this.convertItem();
    return convertedItem;
  }

  static parseCoach(coach: TSummaryCoach): Coach {
    if (!coach) return {} as Coach;
    return {
      name: `${coach.user.first_name} ${coach.user.last_name}`,
      position: coach.position,
      email: coach.user.email,
      phoneNumber: coach.user.phone_number,
      calendarLink: coach.calendar_link,
      imageUrl: coach.img_url,
    };
  }

  convertItem(): TSummary {
    const selectedPA: PainAreaFullDTO | undefined = this.PADTO;
    const currentProgram = this.MPDTO?.current_programs?.sort((a, b) => a.position - b.position).map(ProfileDTOTransformer.parseProgram);
    return {
      memberId: this.DTO?.member?.id,
      coachId: this.DTO?.coach?.id,
      painAreaList: this.DTO?.member?.pain_areas.map(ProfileDTOTransformer.parseShortPainArea),
      mainPainAreaId: selectedPA?.id,
      intakeInProcess: selectedPA?.intake_completed,
      startDate: selectedPA?.start_date,
      coachData: this.DTO?.coach,
      coach: SummaryDTOTransformer.parseCoach(selectedPA?.coach),
      painArea: selectedPA?.pain_area_id,
      intakeCompleted: selectedPA?.intake_completed,
      applicationSubmittedTimestamp: selectedPA?.application_submitted,
      currentWeek: selectedPA?.current_week || 0,
      additionalTerms: this.DTO?.member?.terms_accepted,
      lang: this.DTO?.member?.user?.language || "en",
      lightSensitive: this.DTO?.member?.light_sensitive,
      intakePartResponded: selectedPA?.intake_part_responded,
      exerciseReminder: this.DTO?.member?.exercise_reminder,
      favorites: selectedPA?.favorites,
      videoQuality: this.DTO?.member?.video_quality,
      status: selectedPA?.status?.status,
      currentProgram: (currentProgram && currentProgram.length > 0 ? currentProgram[0] : {}) as CurrentProgram,
      applicationAccepted: selectedPA?.status && selectedPA?.status?.status !== "APPLICANT" && Boolean(selectedPA?.application_submitted), // Removed now we manage this with status
      intakeAnswerPaId: selectedPA?.intake_answer_id,
      currentProgramDays: [],
      pulseAnswered: this.MSDTO?.today_answered_pulse, // dentro del endpoint de las survey answered
      monthlyAnswered: this.MSDTO?.last_month_monthly,
      permissionLevel: "0", // dentro del coach Sólo si es coach
      completedPrograms: selectedPA?.current_week,
      lastStatusDate: 122332455,
      showRecommendation: false, //this.DTO.member.recommendations ?
      lastCoachMeetLink: this.DTO?.member?.current_coach_meet_link,
      intakeAnswerPartResponded: selectedPA?.intake_part_responded, // null , 1, 2
      noChronicPain: selectedPA?.no_chronic_pain,
      introCallDate: selectedPA?.intro_call_date,
      timeZone: selectedPA?.time_zone,
    };
  }
}
