import React, { Suspense, lazy } from "react";
import { Amplify } from "aws-amplify";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Switch, Redirect, Route } from "react-router-dom";
import { store } from "./redux/store";

import "./App.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import Navbar from "../src/components/navbar";
import ScrollToTop from "../src/components/scroll-to-top";

import { Restricted } from "./navigation/Restricted";
import UnAuthenticated from "./navigation/UnAuthenticated";
import { Admin } from "./navigation/Admin";
import Guard from "./navigation/Guard";
import { MembersStatusListRoute } from "./routes/member-status-list";
import PageLoader from "./components/page-loader";
import intakeProcessSelection from "./routes/intake-process-selection";

const AddCoachRoute = lazy(() => import("./routes/add-coach"));
const AdminDashboardRoute = lazy(() => import("./routes/admin-dashboard"));
const ManageProgramsRoute = lazy(() => import("./routes/admin-manage-programs"));
const ApplicantDashboardRoute = lazy(() => import("./routes/applicant-dashboard"));
const CompaniesDashboardRoute = lazy(() => import("./routes/admin-companies"));
const CreateExerciseRoute = lazy(() => import("./routes/create-exercise"));
const EditExerciseRoute = lazy(() => import("./routes/edit-exercise"));
const CreateSurveyRoute = lazy(() => import("./routes/create-survey"));
const CreateProgramRoute = lazy(() => import("./routes/create-program"));
const EditProgramRoute = lazy(() => import("./routes/edit-program"));
const DashboardRoute = lazy(() => import("./routes/dashboard"));
const ExerciseDetailRoute = lazy(() => import("./routes/exercise-detail"));
const ExercisesRoute = lazy(() => import("./routes/exercises"));
const FinishSignInRoute = lazy(() => import("./routes/finish-sign-in"));
const PainAreaSelectionRoute = lazy(() => import("./routes/pain-area-selection"));
const IntakeCompletedRoute = lazy(() => import("./routes/intake-completed"));
const IntakeSurvey = lazy(() => import("./routes/intake-survey"));
const LoginRoute = lazy(() => import("./routes/login/index"));
const ManageSurveysRoute = lazy(() => import("./routes/manage-surveys"));
const PostGraduateRoute = lazy(() => import("./routes/post-graduate"));
const PostDroppedOutRoute = lazy(() => import("./routes/post-dropped-out"));
const ImportUsersRoute = lazy(() => import("./routes/import-users"));
const UsersImportedRoute = lazy(() => import("./routes/import-users/users-imported"));
const MessagesMemberRoute = lazy(() => import("./routes/messages-member"));
const FavoritesAdminRoute = lazy(() => import("./routes/favorite-admin"));
const AdminQuickSurvey = lazy(() => import("./routes/quick-survey"));

const ProgramsRoute = lazy(() => import("./routes/programs"));
const ProfileRoute = lazy(() => import("./routes/profile"));
const SurveyCompletedRoute = lazy(() => import("./routes/survey-completed-route"));
const NoFitRoute = lazy(() => import("./routes/no-fit-route"));
const SeeSurvey = lazy(() => import("./routes/see-survey"));
const MessengerRoute = lazy(() => import("./routes/admin-messenger"));
const ParticularImportRoute = lazy(() => import("./routes/import-users/users-imported/particular-import"));

const PublicHomeRoute = lazy(() => import("./routes/home"));
const TermsRoute = lazy(() => import("./routes/terms"));
const AdditionalTermsRoute = lazy(() => import("./routes/additional-terms"));
const StartExercises = lazy(() => import("./routes/start-exercises"));

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: "us-east-1",
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_CLIENT_ID,
  },
});

export function App() {
  return (
    <Provider store={store}>
      <Guard>
        <Router>
          <Suspense fallback={<PageLoader />}>
            <ScrollToTop />
            <Navbar />
            <Switch>
              <Route exact path="/" component={Restricted(DashboardRoute)} />
              <Route exact path="/start-exercises" component={StartExercises} />
              <Route path="/exercises/:exerciseSlug" component={Restricted(ExerciseDetailRoute)} />
              <Route exact path="/programs" component={Restricted(ProgramsRoute)} />
              <Route exact path="/surveys/submitted" component={SurveyCompletedRoute} />
              <Route path="/surveys/:surveySlug" component={IntakeSurvey} />
              <Route exact path="/intake-survey/submitted" component={IntakeCompletedRoute} />
              <Route exact path="/intake-survey/no-fit" component={NoFitRoute} />
              <Route exact path="/postgraduate" component={PostGraduateRoute} />
              <Route exact path="/intake-process-selection" component={Restricted(intakeProcessSelection)} />
              <Route exact path="/postDroppedOut" component={PostDroppedOutRoute} />
              <Route exact path="/pain-area-selection" component={PainAreaSelectionRoute} />
              <Route path="/survey/:surveyPainArea/:surveyType" component={IntakeSurvey} />
              <Route exact path="/login" component={UnAuthenticated(LoginRoute)} />
              <Route exact path="/import-users" component={Admin(ImportUsersRoute)} />
              <Route exact path="/import-users/users-imported" component={Admin(UsersImportedRoute)} />
              <Route exact path="/import-users/users-imported/particular-import-detail" component={Admin(ParticularImportRoute)} />
              <Route path="/sign-in/:authChallenge" component={UnAuthenticated(FinishSignInRoute)} />
              <Route exact path="/admin" component={Admin(AdminDashboardRoute)} />
              <Route exact path="/admin/applicants" component={Admin(ApplicantDashboardRoute)} />
              <Route exact path="/admin/companies" component={CompaniesDashboardRoute} />
              <Route exact path="/admin/members-status-list" component={MembersStatusListRoute} />
              <Route exact path="/admin/applicants/survey/:surveyId" component={Admin(SeeSurvey)} />
              <Route path="/admin/profile/:memberId/:memberPaId" component={Admin(ProfileRoute)} />
              <Route exact path="/admin/exercises" component={Admin(ExercisesRoute)} />
              <Route exact path="/admin/exercises/new" component={Admin(CreateExerciseRoute)} />
              <Route path="/admin/exercises/:exerciseSlug" component={Admin(EditExerciseRoute)} />
              <Route exact path="/admin/surveys" component={Admin(ManageSurveysRoute)} />
              <Route exact path="/admin/surveys/new" component={Admin(CreateSurveyRoute)} />
              <Route path="/admin/surveys/:surveySlug" component={Admin(CreateSurveyRoute)} />
              <Route exact path="/admin/programs" component={Admin(ManageProgramsRoute)} />
              <Route exact path="/admin/messenger" component={Admin(MessengerRoute)} />
              <Route exact path="/admin/coaches/new" component={Admin(AddCoachRoute)} />
              <Route exact path="/admin/programs/new" component={Admin(CreateProgramRoute)} />
              <Route path="/admin/programs/:programSlug" component={Admin(EditProgramRoute)} />
              <Route path="/admin/programs/:programSlug/exercises/new" component={Admin(CreateExerciseRoute)} />
              <Route path="/admin/quick-survey/:painArea" component={Admin(AdminQuickSurvey)} />
              <Route path="/admin/survey/:userId/:surveyId" component={Admin(SeeSurvey)} />
              <Route path="/admin/messages" component={Admin(MessagesMemberRoute)} />
              <Route path="/admin/favorites" component={Admin(FavoritesAdminRoute)} />
              <Route exact path="/home" component={PublicHomeRoute} />
              <Route exact path="/additionalterms" component={AdditionalTermsRoute} />
              <Route exact path="/terms" component={TermsRoute} />
              <Redirect to="/" />
            </Switch>
          </Suspense>
        </Router>
      </Guard>
    </Provider>
  );
}
///
