/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApiResponse, ApisauceInstance } from "apisauce";
import { Header } from "../../constants";

export interface ApiInstance {
  sauceApi: ApisauceInstance;
  service: string;
  header: Object;
}

type RequestType = () => Promise<ApiResponse<unknown, unknown>>;

class Api {
  sauceApi: ApisauceInstance;
  service: string;
  header: Object;

  constructor(SauceInstance, service, header) {
    this.sauceApi = SauceInstance;
    this.service = service;
    this.header = header;
  }

  addService(path: string): void {
    this.service = path;
  }

  getHeaders() {
    return this.header;
  }

  setHeaders(header: Header): void {
    this.sauceApi.setHeaders(header);
  }

  async doRequestWithRetry(request: RequestType) {
    const maxRetries = 3;
    const slotTime = 500;
    let retryCount = 0;
    do {
      try {
        const res = await request();
        if (!res || !res?.ok) {
          throw res;
        }
        return res;
      } catch (error) {
        window.heap.track("Error", { message: `Error sending request, requests ${retryCount}` });
        window.heap.track("Error Object", { message: `Error sending request, error ${JSON.stringify(error)}` });
        const isLastRequest = retryCount === maxRetries;
        if (isLastRequest) {
          console.error(error);
          return Promise.reject(error);
        }
      }
      const randomTime = Math.floor(Math.random() * slotTime);
      const delay = 2 ** retryCount * slotTime + randomTime;
      // Wait for the exponentially increasing delay period before
      // retrying again.
      await new Promise(resolve => setTimeout(resolve, delay));
    } while (retryCount++ < maxRetries);
  }

  async get(query?: string, params?: Record<string, string>, config?: Object): Promise<any> {
    const param = query ? `${this.service}/${query}` : this.service;
    return this.doRequestWithRetry(() => this.sauceApi.get(param, params, config));
  }

  async post(query: Object | undefined, ...args: any[]): Promise<any> {
    const param = args[0] ? `${this.service}/${args[0]}` : this.service;
    return this.doRequestWithRetry(() => this.sauceApi.post(param, query));
  }

  async patch(id: number, query: Object | undefined): Promise<any> {
    return this.doRequestWithRetry(() => this.sauceApi.patch(`${this.service}/${id}`, query));
  }

  async put(id: string, query: Object | undefined): Promise<any> {
    return await this.doRequestWithRetry(() => this.sauceApi.put(`${this.service}/${id}`, query));
  }

  async delete(id: string): Promise<any> {
    return this.doRequestWithRetry(() => this.sauceApi.delete(this.service + `/${id}`));
  }
}

export { Api };
